import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { convertLangCodeForDB } from '@lib';
import { mainProductTimerSno } from 'src/constants';
import * as ga from '@lib/ga';
import { LanguageCodeEnum, useProductCardsWithBrandQuery, useMainProductListV2Query, useHotProductsQuery } from 'src/generated/graphql';
import { PATH_PRODUCT } from 'src/routes/paths';
import { productClickEventLog } from '@lib/mixpanel/events';
import { ICardClickProps } from '../Card/type';
import { default as CardGroupSkeleton } from './CardGroupSkeleton';
import CardGroup from './CardGroup';

type Props = {
  showSeeMore?: boolean;
  isDisplayRankBadge?: boolean;
  eventPageName?: string;
};

export default function HotModelCardGroup({ isDisplayRankBadge = false, showSeeMore = true, eventPageName, ...rest }: Props) {
  const { i18n, t } = useTranslation(['mainCategory']);
  const { data, error } = useHotProductsQuery({
    variables: {
      count: 8,
    },
  });
  const hotProductListData = data?.hotProducts || [];
  const { data: productCardsData } = useProductCardsWithBrandQuery({
    variables: {
      productNos: hotProductListData,
      languageCode: i18n.language as LanguageCodeEnum,
    },
    skip: hotProductListData.length === 0,
  });
  const mainProducts = (productCardsData?.productCards || []).filter((x) => x?.onDisplay);

  const getProductViewUrl = (val: string) => PATH_PRODUCT.view(val, 0, t('hot'));

  useEffect(() => {
    if (!productCardsData) return;

    const mainProducts = (productCardsData.productCards || []).filter((x) => !x?.onDisplay);
    if (mainProducts.length === 0) return;
    ga.impressionsCardGroupEvent(
      mainProducts.map((x, index) => {
        return {
          index,
          sno: 'sno'.toString(),
          goodsNo: x?.id.toString(),
          title: x?.title,
          brandCd: x?.brand.code,
          price: x?.price,
        };
      }),
    );
  }, [productCardsData]);

  if (!data) return <CardGroupSkeleton />;

  if (error || !hotProductListData || mainProducts.length === 0) return <div />;

  const handleCardClick = ({ actionName, goodsNo, title }: ICardClickProps, order: number) => {
    productClickEventLog({
      pageName: eventPageName,
      actionName: actionName,
      productName: title,
      productNo: goodsNo,
      groupTitle: t('hot'),
      order: order,
    });
  };

  return (
    <CardGroup
      title={t('hot')}
      source={'/new'}
      products={mainProducts}
      getProductViewUrl={getProductViewUrl}
      isDisplayRankBadge={isDisplayRankBadge}
      showSeeMore={showSeeMore}
      handleCardClick={handleCardClick}
      {...rest}
    />
  );
}
